<template>
  <b-row v-if="reqItem">
    <b-col md="12">
      <h6>
        Request Type:
        <b-badge variant="primary" class="text-capitalize ml-1">
          {{ reqItem.type }}
        </b-badge>
      </h6>
      <h6>
        Remaining Dues:
        <b-badge variant="danger" class="ml-1">
          {{ reqItem.meta_dues }}
        </b-badge>
      </h6>
      <h6>
        Requested On:
        <b-badge variant="primary" class="ml-1">
          {{
            new Date(reqItem.request_date).toLocaleDateString("en-UK", {
              year: "numeric",
              day: "numeric",
              month: "short",
            })
          }}
        </b-badge>
      </h6>
      <h6>
        Status:
        <b-badge :variant="getVariant" class="ml-1">
          {{ reqItem.status }}
        </b-badge>
      </h6>
    </b-col>
    <b-col md="12">
      <b-form-group label="Reason">
        <b-form-textarea
          placeholder=""
          rows="3"
          v-model.trim="reqItem.reason"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="12" v-if="reqItem.status != 'pending'">
      <b-form-group label="Note">
        <b-form-textarea
          placeholder="Enter note"
          rows="3"
          v-model.trim="reqItem.note"
          disabled
        />
      </b-form-group>
    </b-col>
    <b-col md="12">
      <div class="d-flex justify-content-between">
        <label class="bv-no-focus-ring col-form-label pt-0">Documents</label>
      </div>
      <p v-if="docList.length == 0" class="font-weight-bold text-center">
        No documents found
      </p>
      <div v-else class="d-flex flex-wrap">
        <div
          class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-50 mb-1"
          style="width: 110px; height: 96px; position: relative"
          v-for="(file, index) in docList"
          :key="index"
          @click="openFile(file)"
        >
          <feather-icon
            :icon="isImage(file) ? 'ImageIcon' : 'FileTextIcon'"
            size="30"
            class="text-primary"
          />
        </div>
      </div>
    </b-col>
    <b-col md="12" v-if="reqItem.status == 'accepted'">
      <div class="d-flex justify-content-between">
        <label class="bv-no-focus-ring col-form-label pt-0"
          >Approval Documents</label
        >
      </div>
      <p v-if="appDocs.length == 0" class="font-weight-bold text-center">
        No documents found
      </p>
      <div v-else class="d-flex flex-wrap">
        <div
          class="border-dashed d-flex flex-column justify-content-center align-items-center cursor-pointer rounded mr-50 mb-1"
          style="width: 110px; height: 96px; position: relative"
          v-for="(file, index) in appDocs"
          :key="index"
          @click="openFile(file)"
        >
          <feather-icon
            :icon="isImage(file) ? 'ImageIcon' : 'FileTextIcon'"
            size="30"
            class="text-primary"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    BInputGroup,
    BFormTextarea,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    reqItem: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getVariant() {
      if (this.reqItem.status == "accepted") return "success";
      else return "danger";
    },
    docList() {
      if (this.reqItem.document) return this.reqItem.document.split(",");
      else return [];
    },
    appDocs() {
      if (this.reqItem.approved_document)
        return this.reqItem.approved_document.split(",");
      else return [];
    },
  },
  methods: {
    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/i.test(url);
    },
    openFile(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style></style>
